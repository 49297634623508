const menu = {
  state: {
    current: "",
  },

  mutations: {
    SET_CURRENT: (state, current) => {
      state.current = current;
    },
  },

  actions: {},
};

export default menu;
