import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import menu from "./modules/menu";
import permission from "./modules/permission";
import settings from "./modules/settings";
import app from "./modules/app";
import getters from "./getters";
import tagsView from "./modules/tagsView";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    menu,
    permission,
    settings,
    app,
    tagsView,
  },
  getters,
});
