// // 子路由要渲染到父路由的<router-view />中
const RouteView = {
  name: "RouteView",
  render: (h) => h("router-view"),
};
// // 定义页面路由
// // 现场管理系统
const constantRouterComponents = {
  // 登陆
  Login: () => import("@/views/login"),
  // 工地管理
  BasicLayout: () => import("@/layouts/BasicLayout"),

  // 数据统计
  DailySheet: () => import("@/views/dataStatistics/DailySheet.vue"),
  SiteImage: () => import("@/views/dataStatistics/SiteImage.vue"), //工地形象统计
  AttendStatistics: () => import("@/views/dataStatistics/AttendStatistics.vue"), //考勤统计
  HomeMaster: () => import("@/views/home/HomeMaster.vue"), //我要掌握

  // 工人维度
  WorkerInfo: () => import("@/views/home/worker/WorkerInfo.vue"), //工人基本信息
  ProjectExperience: () => import("@/views/home/worker/ProjectExperience.vue"), //工人项目经历
  // BreakRecord: () => import("@/views/home/worker/BreakRecord.vue"), //违章记录
  ScenePatrol: () => import("@/views/home/worker/ScenePatrol.vue"), //人员现场巡查记录
  BreakRecord: () => import("@/views/home/worker/BreakRecord.vue"), //工人违章记录
  planPatrol: () => import("@/views/home/worker/planPatrol.vue"), //人员计划核查记录
  CleanChange: () => import("@/views/home/worker/CleanChange.vue"), //工人施工工地整洁度变化
  WorkerMap: () => import("@/views/home/worker/WorkerMap.vue"), //人员地图

  // 访客维度
  VisitorVisit: () => import("@/views/home/visitor/VisitorVisit.vue"), //陌生访客到访情况

  // 工地维度
  SiteInfo: () => import("@/views/home/site/SiteInfo.vue"), //基本信息
  SiteAttendance: () => import("@/views/home/site/SiteAttendance.vue"), //工地人员考勤信息
  SitePatrol: () => import("@/views/home/site/SitePatrol.vue"), //监管人员巡查信息
  SitelongrangePatrol: () =>
    import("@/views/home/site/SitelongrangePatrol.vue"), //远程巡查记录
  SiteplanPatrol: () => import("@/views/home/site/SiteplanPatrol.vue"), //计划核查记录
  SiteVidicon: () => import("@/views/home/site/SiteVidicon.vue"), //工地摄像机在线分析
  SiteBreak: () => import("@/views/home/site/SiteBreak.vue"), //工地违章信息

  // 业主维度
  OwnerInfo: () => import("@/views/home/owner/OwnerInfo.vue"), //业主资料
  OwnerVideoaccess: () => import("@/views/home/owner/OwnerVideoaccess.vue"), //视频访问记录
  OwnerVisit: () => import("@/views/home/owner/OwnerVisit.vue"), //现场到访记录
  OwnerInteract: () => import("@/views/home/owner/OwnerInteract.vue"), //业主互动记录
  OwnerFeedback: () => import("@/views/home/owner/OwnerFeedback.vue"), //业主信息反馈(含投诉)
  OwnerPromise: () => import("@/views/home/owner/OwnerPromise.vue"), //业主承诺管理
  OwnerProjectwarn: () => import("@/views/home/owner/OwnerProjectwarn.vue"), //业主项目工期预警记录
  OwnerQualitywarn: () => import("@/views/home/owner/OwnerQualitywarn.vue"), //施工质量预警
  OwnerWarnrecord: () => import("@/views/home/owner/OwnerWarnrecord.vue"), //工地报警记录

  // 管理维度
  ManageSupervise: () => import("@/views/home/manage/ManageSupervise.vue"), //监管人员督察
  ManageMap: () => import("@/views/home/manage/ManageMap.vue"), //现场巡查地图
  ManageInspect: () => import("@/views/home/manage/ManageInspect.vue"), //规定动作核查
};

export const asyncRouterMap = [
  // export const constantRoutes = [
  // 首页
  {
    path: "",
    redirect: "/HomeMaster",
    meta: { title: "首页", icon: "dashboard" },
    component: RouteView,
    activeMenu: "/HomeMaster",
    // path: "/HomeMaster",
    name: "HomeMaster",
    // meta: { title: "首页", showMenu: false, memoryRouter: false },
    // component: constantRouterComponents.HomeMaster,
    // component: () => import("@/views/home/home.vue"),
    children: [
      {
        path: "/HomeMaster",
        name: "HomeMaster",
        meta: {
          title: "首页",
          icon: "tools",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.HomeMaster,
      },
      // 工人维度
      {
        path: "/WorkerInfo",
        name: "WorkerInfo",
        meta: {
          title: "人员基本信息",
          showMenu: false,
          memoryRouter: false, //是否记忆当前路由为常用工具
        },
        hidden: true,
        component: constantRouterComponents.WorkerInfo,
      },
      {
        path: "/ProjectExperience",
        name: "ProjectExperience",
        meta: {
          title: "项目经历",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.ProjectExperience,
      },
      {
        path: "/BreakRecord",
        name: "BreakRecord",
        meta: {
          title: "违章记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.BreakRecord,
      },
      {
        path: "/ScenePatrol",
        name: "ScenePatrol",
        meta: {
          title: "人员现场巡查记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.ScenePatrol,
      },
      {
        path: "/planPatrol",
        name: "planPatrol",
        meta: {
          title: "人员计划核查记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.planPatrol,
      },
      {
        path: "/WorkerMap",
        name: "WorkerMap",
        meta: {
          title: "人员地图",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.WorkerMap,
      },
      {
        path: "/CleanChange",
        name: "CleanChange",
        meta: {
          title: "施工工地整洁度变化",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.CleanChange,
      },
      // 访客维度
      {
        path: "/VisitorVisit",
        name: "VisitorVisit",
        meta: {
          title: "陌生访客到访情况",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.VisitorVisit,
      },
      // 工地维度
      {
        path: "/SiteInfo",
        name: "SiteInfo",
        meta: {
          title: "工地基本信息",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteInfo,
      },
      {
        path: "/SiteAttendance",
        name: "SiteAttendance",
        meta: {
          title: "工地人员考勤信息",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteAttendance,
      },
      {
        path: "/SitePatrol",
        name: "SitePatrol",
        meta: {
          title: "现场巡查记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SitePatrol,
      },
      {
        path: "/SitelongrangePatrol",
        name: "SitelongrangePatrol",
        meta: {
          title: "远程巡查记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SitelongrangePatrol,
      },
      {
        path: "/SiteplanPatrol",
        name: "SiteplanPatrol",
        meta: {
          title: "计划核查记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteplanPatrol,
      },
      {
        path: "/SiteVidicon",
        name: "SiteVidicon",
        meta: {
          title: "工地摄像机在线分析",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteVidicon,
      },
      {
        path: "/SiteBreak",
        name: "SiteBreak",
        meta: {
          title: "工地违章及整洁度信息",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.SiteBreak,
      },
      // 业主维度
      {
        path: "/OwnerInfo",
        name: "OwnerInfo",
        meta: {
          title: "业主资料",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerInfo,
      },
      {
        path: "/OwnerVideoaccess",
        name: "OwnerVideoaccess",
        meta: {
          title: "视频访问记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerVideoaccess,
      },
      {
        path: "/OwnerVisit",
        name: "OwnerVisit",
        meta: {
          title: "现场到访记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerVisit,
      },
      {
        path: "/OwnerInteract",
        name: "OwnerInteract",
        meta: {
          title: "业主互动记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerInteract,
      },
      {
        path: "/OwnerFeedback",
        name: "OwnerFeedback",
        meta: {
          title: "业主信息反馈(含投诉)",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerFeedback,
      },
      {
        path: "/OwnerPromise",
        name: "OwnerPromise",
        meta: {
          title: "业主承诺管理",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerPromise,
      },
      {
        path: "/OwnerProjectwarn",
        name: "OwnerProjectwarn",
        meta: {
          title: "业主项目工期预警记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerProjectwarn,
      },
      {
        path: "/OwnerQualitywarn",
        name: "OwnerQualitywarn",
        meta: {
          title: "施工质量预警",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerQualitywarn,
      },
      {
        path: "/OwnerWarnrecord",
        name: "OwnerWarnrecord",
        meta: {
          title: "工地报警记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.OwnerWarnrecord,
      },
      // 管理维度
      {
        path: "/ManageSupervise",
        name: "ManageSupervise",
        meta: {
          title: "监管人员督察",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.ManageSupervise,
      },
      {
        path: "/ManageMap",
        name: "ManageMap",
        meta: {
          title: "现场巡查地图",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.ManageMap,
      },
      {
        path: "/ManageInspect",
        name: "ManageInspect",
        meta: {
          title: "计划核查记录",
          showMenu: false,
          memoryRouter: false,
        },
        hidden: true,
        component: constantRouterComponents.ManageInspect,
      },
    ],
  },
];

// /**
//  * 基础路由
//  * @type { *[] }
//  */
export const constantRouterMap = [
  {
    path: "/",
    name: "BasicLayout",
    component: constantRouterComponents.BasicLayout,
    redirect: "/login",
    children: asyncRouterMap,
    // children: constantRoutes,
  },
  {
    path: "/login",
    name: "login",
    component: constantRouterComponents.Login,
  },
  {
    path: "/DataView",
    name: "DataView",
    component: () => import("@/views/datav/index.vue"),
  },
  {
    path: "/PatrolView",
    name: "PatrolView",
    component: () => import("@/views/inspection/index.vue"),
  },
  {
    path: "/PatrolDetail",
    name: "PatrolView",
    component: () => import("@/views/inspection/detail.vue"),
  },
  // {
  //   path: "/Inspection",
  //   name: "Inspection",
  //   component: () => import("@/views/inspection/index.vue"),
  // },
  {
    path: "/CaseFieldView",
    name: "CaseFieldView",
    component: () => import("@/views/caseField/index.vue"),
  },
  {
    path: "/404",
    component: () =>
      import(/* webpackChunkName: "fail" */ "@/views/exception/404"),
  },
  {
    path: "/401",
    component: () =>
      import(/* webpackChunkName: "fail" */ "@/views/exception/401"),
  },
  {
    path: "/500",
    component: () =>
      import(/* webpackChunkName: "fail" */ "@/views/exception/500"),
  },
  // {
  //   path: "*",
  //   redirect: "/404",
  //   hidden: true,
  // },
  {
    path: "/customPreviewView",
    name: "customPreviewView",
    component: () => import("@/views/customField/customPreview.vue"),
  },
  {
    path: "/BoardTotalView",
    name: "BoardTotalView",
    component: () => import("@/views/adminBoard/BoardTotal.vue"),
  },
  {
    path: "/ExhibitionBoardView",
    name: "ExhibitionBoardView",
    component: () => import("@/views/exhibitionboard/ExhibitionBoard.vue"),
  },
  {
    path: "/MapFieldboardView",
    name: "MapFieldboard",
    component: () => import("@/views/mapField"),
  },
  {
    path: "/VideoRecordView",
    name: "videoRecord",
    component: () => import("@/views/videoRecord"),
  },
];
