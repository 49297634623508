// import { constantRoutes } from "@/router";
import { asyncRouterMap } from "@/config/router.config";
import BasicLayout from "@/layouts/BasicLayout";
import { toCamelCase } from "@/utils/global";

const permission = {
  state: {
    routes: [],
    addRoutes: [],
    sidebarRouters: [], // 左侧边菜单的路由，被 Sidebar/index.vue 使用
    topbarRouters: [], // 顶部菜单的路由，被 TopNav/index.vue 使用
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes;
      state.routes = asyncRouterMap.concat(routes);
    },
    SET_DEFAULT_ROUTES: (state, routes) => {
      state.defaultRoutes = asyncRouterMap.concat(routes);
    },
    SET_TOPBAR_ROUTES: (state, routes) => {
      state.topbarRouters = routes;
    },
    SET_SIDEBAR_ROUTERS: (state, routes) => {
      state.sidebarRouters = routes;
    },
  },
  actions: {
    /**
     * 生成路由
     *
     * @param commit commit 函数
     * @param menus  路由参数
     */
    GenerateRoutes({ commit, getters, dispatch, rootState }, menus) {
      // let that = this;
      // let rootStateXt = that.$store.state.user.system;
      return new Promise((resolve) => {
        // 将 menus 菜单，转换为 route 路由数组
        const sdata = JSON.parse(JSON.stringify(menus)); // 【重要】用于菜单中的数据
        const rdata = JSON.parse(JSON.stringify(menus)); // 用于最后添加到 Router 中的数据
        const sidebarRoutes = filterAsyncRouter(sdata);
        const rewriteRoutes = filterAsyncRouter(rdata, false, true);
        // rewriteRoutes.push({ path: "*", redirect: "/404", hidden: true });
        commit("SET_ROUTES", rewriteRoutes);
        // 如果是交付系统，筛掉首页
        // console.log("rootStateXt", rootState.user.system);
        if (rootState.user.system == "jf") {
          let newasyncRouterMap = asyncRouterMap.filter((el) => {
            return el.name != "HomeMaster";
          });
          commit(
            "SET_SIDEBAR_ROUTERS",
            newasyncRouterMap.concat(sidebarRoutes)
          );
          // console.log("newasyncRouterMap", newasyncRouterMap);
        } else {
          commit("SET_SIDEBAR_ROUTERS", asyncRouterMap.concat(sidebarRoutes));
          // console.log("asyncRouterMap", asyncRouterMap);
        }

        commit("SET_DEFAULT_ROUTES", sidebarRoutes);
        commit("SET_TOPBAR_ROUTES", sidebarRoutes);
        resolve(rewriteRoutes);
      });
    },
  },
};

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter((route) => {
    // 将 ruoyi 后端原有耦合前端的逻辑，迁移到此处
    // 处理 meta 属性
    route.meta = {
      title: route.name,
      icon: route.icon,
      noCache: !route.keepAlive,
      memoryRouter: route.memoryRouter,
    };
    route.hidden = !route.visible;
    // 处理 name 属性
    if (route.componentName && route.componentName.length > 0) {
      route.name = route.componentName;
    } else {
      // 路由地址转首字母大写驼峰，作为路由名称，适配 keepAlive
      route.name = toCamelCase(route.path, true);
      // 处理三级及以上菜单路由缓存问题，将 path 名字赋值给 name
      if (route.path.indexOf("/") !== -1) {
        const pathArr = route.path.split("/");
        route.name = toCamelCase(pathArr[pathArr.length - 1], true);
      }
    }

    // console.log(" route.component", route.component);

    // 处理 component 属性
    if (route.children && route.children.length > 0) {
      // 父节点
      // if (route.parentId === 0) {
      //   route.component = Layout;
      // } else {
      //   route.component = ParentView;
      // }
      route.component = BasicLayout;
    } else {
      // 根节点
      route.component = loadView(route.component);
      // route.component = route.component;
      // route.component = (resolve) => require([`@/${route.component}`], resolve);

      // console.log("!!!", route);
    }

    // filterChildren
    if (type && route.children) {
      route.children = filterChildren(route.children);
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type);
      route.alwaysShow =
        route.alwaysShow !== undefined ? route.alwaysShow : true;
    } else {
      delete route["children"];
      delete route["alwaysShow"]; // 如果没有子菜单，就不需要考虑 alwaysShow 字段
    }
    // console.log("route", route);

    return true;
  });
}

function filterChildren(childrenMap, lastRouter = false) {
  let children = [];
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (!el.component && !lastRouter) {
        el.children.forEach((c) => {
          c.path = el.path + "/" + c.path;
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }
          children.push(c);
        });
        return;
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + "/" + el.path;
    }
    children = children.concat(el);
  });
  return children;
}

export const loadView = (view) => {
  // 路由懒加载
  // console.log("路由懒加载", view);

  // return (resolve) => require([`@/views/${view}`], resolve);
  // return (resolve) => require([`@${view}`], resolve);
  return (resolve) => require(["@/" + view], resolve);
};

export default permission;
