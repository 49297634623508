var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'has-logo': _vm.showLogo },style:({
    backgroundColor:
      _vm.settings.sideTheme === 'theme-dark'
        ? _vm.variables.menuBackground
        : _vm.variables.menuLightBackground,
  })},[_c('el-scrollbar',{class:_vm.settings.sideTheme,attrs:{"wrap-class":"scrollbar-wrapper"}},[_c('el-menu',{attrs:{"default-active":_vm.activeMenu,"collapse":_vm.isCollapse,"background-color":_vm.settings.sideTheme === 'theme-dark'
          ? _vm.variables.menuBackground
          : _vm.variables.menuLightBackground,"text-color":_vm.settings.sideTheme === 'theme-dark'
          ? _vm.variables.menuColor
          : _vm.variables.menuLightColor,"unique-opened":true,"active-text-color":_vm.settings.theme,"collapse-transition":false,"mode":"vertical"}},_vm._l((_vm.sidebarRouters),function(route,index){return _c('sidebar-item',{key:route.path + index,attrs:{"item":route,"base-path":route.path}})}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }