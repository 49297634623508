<template>
  <div>
    <a-drawer
      title="修改密码"
      placement="right"
      :width="750"
      :visible="visible"
      @close="onClose"
      :destroyOnClose="true"
    >
      <div class="dest-cont">
        <a-form
          ref="formRef"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-item label="原密码">
            <a-input-password
              v-model="formState.old_pass"
              @change="changeInput($event, 'old_pass')"
              placeholder="请输入原密码"
            />
          </a-form-item>
          <a-form-item label="新密码">
            <a-input-password
              v-model="formState.new_pass"
              @change="changeInput($event, 'new_pass')"
              placeholder="请输入新密码"
            />
          </a-form-item>
          <a-form-item label="确认新密码">
            <a-input-password
              class="input-pass"
              v-model="formState.new_pass_confirm"
              @change="changeInput($event, 'new_pass_confirm')"
              placeholder="请确认新密码"
            />
            <span v-show="!!tipsText" class="tips-text">{{ tipsText }}</span>
          </a-form-item>
          <a-form-item label="密码规则">
            <span>8-20位，可输入大小写字母和数字，不支持特殊字符</span>
          </a-form-item>
        </a-form>
        <div class="dest-bottom" style="margin-top: 20px">
          <a-button @click="viewCancel()">返回</a-button>
          <a-button
            @click="editSubmit()"
            :disabled="!comfirmable"
            type="primary"
            >确认</a-button
          >
        </div>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import { UpdatePass, UpdatePassWord } from "@/api/role";
import md5 from "md5";

export default {
  name: "UserEditPass",
  props: {
    u_id: {
      // type: String,
      default: "",
    },
  },
  data() {
    return {
      // 弹出框
      visible: true,
      timer: null,
      tipsText: "",
      validateStatus: {
        old_pass: false,
        new_pass: false,
        new_pass_confirm: false,
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      user_id: "",
      formState: {
        old_pass: "",
        new_pass: "",
        new_pass_confirm: "",
      },
    };
  },
  created() {
    this.user_id = this.u_id;
  },
  computed: {
    comfirmable: function () {
      if (
        this.validateStatus.old_pass &&
        this.validateStatus.new_pass &&
        this.validateStatus.new_pass_confirm
      ) {
        return true;
      } else {
        return false;
      }
      // return false;
    },
  },
  methods: {
    onClose() {
      this.$emit("onClose");
    },
    changeInput(event, type) {
      let self = this;
      let validate = function () {
        // 判断确认密码是否相等
        let result = false;
        console.log(type);
        if (
          (type === "new_pass" && self.formState.new_pass_confirm !== "") ||
          type === "new_pass_confirm"
        ) {
          if (self.formState.new_pass !== self.formState.new_pass_confirm) {
            self.tipsText = "确认密码与原密码不一致！";
            document.getElementsByClassName(
              "input-pass"
            )[0].childNodes[0].style.borderColor = "red";
            self.validateStatus.new_pass_confirm = false;
          } else {
            self.tipsText = "";
            document.getElementsByClassName(
              "input-pass"
            )[0].childNodes[0].style.borderColor = "#d9d9d9";
            self.validateStatus.new_pass_confirm = true;
          }
        }
        // 正则判断
        let value = event.target._value;
        let reg = /^[1-9a-zA-Z]{6,20}$/;
        result = reg.test(value);
        self.validateStatus[type] = result;
        if (!result) {
          event.target.style.borderColor = "red";
        } else {
          event.target.style.borderColor = "#d9d9d9";
        }
      };
      // 输入防抖
      this.inputDubounce(validate, 1000);
    },
    inputDubounce(fun, time) {
      if (this.timer !== null) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(fun, time);
    },
    // 确认
    editSubmit() {
      let params = {
        user_id: this.user_id,
        // old_pass: md5(this.formState.old_pass),
        // new_pass: md5(this.formState.new_pass),
        oldPassword: md5(this.formState.old_pass),
        newPassword: md5(this.formState.new_pass),
      };
      console.log(params);
      UpdatePassWord(params).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.$emit("onClose");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 取消
    viewCancel() {
      this.$emit("onClose");
    },
  },
  beforeDestroy() {
    this.timer = null;
  },
};
</script>
<style lang="less" scoped>
.tips-text {
  position: absolute;
  left: 0;
  top: 20px;
  font-size: 12px;
  color: red;
}
.dest-bottom {
  text-align: right;
  button {
    margin-left: 16px;
  }
}
</style>
