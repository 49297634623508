import Vue from "vue";
import VueRouter from "vue-router";
import { constantRouterMap } from "@/config/router.config";

const routes = constantRouterMap;

Vue.use(VueRouter);
const router = new VueRouter({
  // base: process.env.VUE_APP_APP_NAME ? process.env.VUE_APP_APP_NAME : "/login",
  // mode: "history", // 去掉url中的#
  // scrollBehavior: () => ({ y: 0 }),
  routes,
});

// 防止重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;

const createRouter = () =>
  new VueRouter({
    mode: "history",
    routes: constantRouterMap,
  });
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}
