import { DoLogin, DoLogout } from "@/api/login";
import { GetUser, GetPermissionInfo } from "@/api/role";
import { GetCompanies } from "@/api/device";
const user = {
  state: {
    token: "",
    name: "",
    id: "",
    roles: [], //现场管理系统菜单权限
    info: {},
    isShowBtns: [], //现场管理系统按钮权限
    rolesJF: [], //交付系统菜单权限
    isShowBtnsJF: [], //交付系统按钮权限
    system: "",
    permissions: [],
    avatar: "",
    menus: [],
    CompanyList: "",
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_ID: (state, id) => {
      state.id = id;
      // console.log(state);
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
      // console.log("roles", roles);
      // console.log(sessionStorage.getItem("roles"));
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    SET_BTNS: (state, isShowBtns) => {
      state.isShowBtns = isShowBtns;
    },
    SET_ROLESJF: (state, rolesJF) => {
      state.rolesJF = rolesJF;
    },
    SET_BTNSJF: (state, isShowBtnsJF) => {
      state.isShowBtnsJF = isShowBtnsJF;
    },
    SET_SYSTEM: (state, system) => {
      state.system = system;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_NICKNAME: (state, nickname) => {
      state.nickname = nickname;
    },
    SET_MENUS: (state, menus) => {
      state.menus = menus;
    },
    SET_COMPANYLIST: (state, CompanyList) => {
      state.CompanyList = CompanyList;
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        DoLogin(userInfo)
          .then((response) => {
            if (response.code == "200") {
              if (response.data.token) {
                sessionStorage.setItem("token", response.data.token);
                // console.log("user--token", response.data.token);

                sessionStorage.setItem("tenant_id", response.data.tenant_id);
                commit("SET_TOKEN", response.data.token);
                // sessionStorage.setItem("token", response.data);
                // commit("SET_TOKEN", response.data);
                //获取当前用户的信息
                GetPermissionInfo()
                  .then((res) => {
                    let menus = res.data.menus;
                    commit("SET_PERMISSIONS", res.data.permissions);
                    commit("SET_MENUS", menus);
                    if (menus.osmcore.length > 0) {
                      commit("SET_SYSTEM", "xc");
                    } else {
                      commit("SET_SYSTEM", "jf");
                    }
                  })
                  .then(() => {});
                // 获取分公司信息
                GetCompanies().then((res) => {
                  let CompanyList = [];
                  CompanyList.push({ bc_id: "", bc_name: "全部" });
                  for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    CompanyList.push(element);
                  }
                  commit("SET_COMPANYLIST", JSON.stringify(CompanyList));
                });
              }
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        GetPermissionInfo()
          .then((res) => {
            // 没有 data 数据，赋予个默认值
            if (!res) {
              res = {
                data: {
                  roles: [],
                  user: {
                    id: "",
                    avatar: "",
                    userName: "",
                    nickname: "",
                  },
                },
              };
            }

            // res = res.data; // 读取 data 数据
            const user = res.data.user;
            // const avatar =
            //   user.avatar === "" || user.avatar == null
            //     ? require("@/assets/images/profile.jpg")
            //     : user.avatar;
            const avatar = "";
            if (res.data.roles && res.data.roles.length > 0) {
              // 验证返回的roles是否是一个非空数组
              commit("SET_ROLES", res.data.roles);
              commit("SET_PERMISSIONS", res.data.permissions);
            } else {
              commit("SET_ROLES", ["ROLE_DEFAULT"]);
              // role_default
            }
            commit("SET_MENUS", res.data.menus);

            commit("SET_ID", user.id);
            // commit("SET_NAME", user.userName);
            commit("SET_NAME", user.nickname);
            commit("SET_NICKNAME", user.nickname);
            commit("SET_AVATAR", user.avatar);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve) => {
        DoLogout()
          .then((response) => {
            if (response.code == "200") {
              localStorage.removeItem("userMsg");
              // this.$store.commit("SET_ROLES", "");
              // this.$store.commit("SET_ROLESJF", "");
              sessionStorage.removeItem("token");
              // this.$router.push({ path: "/login" });
              sessionStorage.removeItem("roles");
              commit("SET_ROLES", []);
              commit("SET_PERMISSIONS", []);

              localStorage.removeItem("path", "");
              localStorage.removeItem("SET_SYSTEM");
              // this.$store.commit("SET_CURRENT", "");
              // this.$store.commit("SET_SYSTEM", "");
            } else {
              reject(response);
            }
            localStorage.removeItem("userMsg");
            this.$store.commit("SET_ROLES", "");
            this.$store.commit("SET_PERMISSIONS", "");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("roles");
            sessionStorage.removeItem("tenant_id");
            localStorage.removeItem("path", "");
            localStorage.removeItem("SET_SYSTEM");
          })
          .catch((error) => {
            console.log("err111", error);
          });
      });
    },
  },
};

export default user;
