/**
 * 按需引入ant design的组件，使用到的UI组件都要在此定义
 * 定义后的组件可以在全局使用，无需再次引入
 */
import Vue from "vue";

import {
  ConfigProvider,
  Alert,
  Button,
  Card,
  Row,
  Col,
  Layout,
  Menu,
  Breadcrumb,
  Icon,
  Form,
  Input,
  InputNumber,
  Select,
  Cascader,
  Checkbox,
  DatePicker,
  Table,
  Pagination,
  Modal,
  Drawer,
  Steps,
  Tag,
  Empty,
  Tooltip,
  Badge,
  Result,
  Dropdown,
  Calendar,
  Upload,
  Popover,
  Transfer,
  Carousel,
  Tabs,
  Radio,
  TimePicker,
  List,
  Avatar,
  Statistic,
  Collapse,
  TreeSelect,
  FormModel,
  Popconfirm,
  Descriptions,
  Spin,
  Switch,
  Tree,
  Affix,
  Progress,
  Slider,
  Divider,
} from "ant-design-vue";
Vue.use(ConfigProvider);
Vue.use(Alert);
Vue.use(Button);
Vue.use(Card);
Vue.use(Row);
Vue.use(Col);
Vue.use(Layout);
Vue.use(Menu);
Vue.use(Breadcrumb);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Select);
Vue.use(Cascader);
Vue.use(Checkbox);
Vue.use(DatePicker);
Vue.use(Table);
Vue.use(Pagination);
Vue.use(Modal);
Vue.use(Drawer);
Vue.use(Steps);
Vue.use(Tag);
Vue.use(Empty);
Vue.use(Tooltip);
Vue.use(Badge);
Vue.use(Result);
Vue.use(Dropdown);
Vue.use(Calendar);
Vue.use(Upload);
Vue.use(Popover);
Vue.use(Transfer);
Vue.use(Carousel);
Vue.use(Tabs);
Vue.use(Radio);
Vue.use(TimePicker);
Vue.use(List);
Vue.use(Avatar);
Vue.use(Statistic);
Vue.use(Collapse);
Vue.use(TreeSelect);
Vue.use(FormModel);
Vue.use(Popconfirm);
Vue.use(Descriptions);
Vue.use(Spin);
Vue.use(Switch);
Vue.use(Tree);
Vue.use(Affix);
Vue.use(Progress);
Vue.use(Slider);
Vue.use(Divider);
